import React, {useContext} from 'react';
import {Alert, Button, Grid, ListItemButton, Typography} from '@mui/material';
import BlankCard from 'components/BlankCard/BlankCard';
import List from 'components/List/List';
import ModalController from 'controllers/ModalController';
import RoleGuard from 'commons/guards/roleGuard';
import StatusList from 'components/StatusList/StatusList';
import {useParams} from 'react-router-dom';
import {GET_LEAVE_DETAILS} from 'graphql/absences/Querys';
import {
  LeaveRequest,
  LEAVE_STATUS_APPROVED,
  LEAVE_STATUS_IN_PROCESS,
  LEAVE_STATUS_REJECTED,
  leaveStatusLabels,
  LEAVE_STATUS_DRAFT,
  LEAVE_STATUS_PENDING,
} from 'models/leaveRequest';
import LeaveUpdateApprovalStatusModal from 'viewComponents/LeaveUpdateApprovalStatusModal/LeaveUpdateApprovalStatusModal';
import GqlQueryController from 'controllers/GqlQueryController/GqlQueryController';
import {Evidence, EvidenceType} from 'models/evidence';
import {Approval} from 'models/approval';
import {GqlImagePreviewModal} from 'controllers/GqlImagePreviewModal/GqlImagePreviewModal';
import {ReactComponent as ApprovedIcon} from 'assets/approved.svg';
import {ReactComponent as InProgessIcon} from 'assets/in_progress.svg';
import {ReactComponent as PendingIcon} from 'assets/pending.svg';
import {ReactComponent as RejectedIcon} from 'assets/rejected.svg';
import {authContext} from 'contextApi/context/auth';
import LeaveRequestCancellationModal from 'viewComponents/LeaveRequestCancellationModal/LeaveRequestCancellationModal';
import LeaveRequestUpdateModal from 'viewComponents/LeaveRequestUpdateModal/LeaveRequestUpdateModal';
import ImageIcon from '@mui/icons-material/Image';
import {useTranslation} from 'react-i18next';
import dayjs from 'dayjs';
import './LeaveRequestDetail.scss';
import ViewTitle from 'viewComponents/ViewTitle/ViewTitle';
import LeaveRequestDeleteModal from 'viewComponents/LeaveRequestDeleteModal/LeaveRequestDeleteModal';
import LeaveRequestInProcessModal from 'viewComponents/LeaveRequestInProcessModal/LeaveRequestInProcessModal';
import {END_AT_MORNING, HalfDay, START_AT_EVENING} from 'models/halfDay';
import {parseDate, areEquals} from 'commons/dates';
import LeaveRequestApprovedCancellationModal from 'viewComponents/LeaveRequestApprovedCancellationModal/LeaveRequestApprovedCancellationModal';

const LeaveRequestDetail: React.FC = () => {
  const {id} = useParams<{id: string}>();
  const initialState: Partial<LeaveRequest> = {};
  const {userDetails} = useContext(authContext);
  const {t} = useTranslation();

  const processEvidences = (evidences?: Evidence[]): Evidence[] => {
    const validOptions = [
      EvidenceType.MANAGER_EVIDENCE,
      EvidenceType.MEDICAL_PRESCRIPTION,
      EvidenceType.CUSTOMER_EVIDENCE,
    ];
    const list = evidences?.filter(evidence => {
      return validOptions.indexOf(evidence.evidenceType) > -1;
    });
    return list || [];
  };

  const getEvidencIcon = (status: string) => {
    switch (status) {
      case LEAVE_STATUS_APPROVED:
        return <ApprovedIcon />;
      case LEAVE_STATUS_REJECTED:
        return <RejectedIcon />;
      case LEAVE_STATUS_IN_PROCESS:
        return <InProgessIcon />;
      default:
        return <PendingIcon />;
    }
  };

  const isApproverPending = (
    approvals: Approval[],
    userId: string | undefined
  ) =>
    approvals?.some(
      approval =>
        approval.approvalId === userId &&
        approval.approvalStatus === LEAVE_STATUS_PENDING
    );

  const extraInfoPeriod = ({
    startDate,
    endDate,
    scheduleOptions = [],
  }: {
    startDate: string;
    endDate: string;
    scheduleOptions?: HalfDay[];
  }): string => {
    const startAtEvening = scheduleOptions.includes(START_AT_EVENING);
    const endAtMorning = scheduleOptions.includes(END_AT_MORNING);

    if (areEquals(parseDate(startDate), parseDate(endDate))) {
      if (startAtEvening) {
        return t('leaves.LeaveRequestDetail.request.halfDayMorning');
      }
      if (endAtMorning) {
        return t('leaves.LeaveRequestDetail.request.halfDayEvening');
      }
    }

    let textExtraInfo = '';
    if (startAtEvening) {
      textExtraInfo += t('leaves.LeaveRequestDetail.request.startAtEvening');
    }
    if (startAtEvening && endAtMorning) {
      textExtraInfo += ' - ';
    }
    if (endAtMorning) {
      textExtraInfo += t('leaves.LeaveRequestDetail.request.endAtMorning');
    }

    return textExtraInfo.trim();
  };

  return (
    <GqlQueryController
      initialState={initialState}
      QUERY={GET_LEAVE_DETAILS}
      rootField="leaveRequestDetails"
      variables={{
        leaveRequestId: id,
      }}
    >
      {({data}) => (
        <div className="LeaveRequestDetail w-full flex flex-col flex-wrap pt-12">
          <div className="flex justify-between mb-6">
            <ViewTitle>{t('leaves.LeaveRequestDetail.title')}</ViewTitle>
            <div className="actions">
              {isApproverPending(data.approvals, userDetails?.data?.id) && (
                <RoleGuard renderForSupervisor>
                  <ModalController
                    trigger={({onToggleModal}) => (
                      <Button
                        variant="outlined"
                        onClick={() => {
                          onToggleModal();
                        }}
                      >
                        {t('leaves.LeaveRequestDetail.rejectButton')}
                      </Button>
                    )}
                    modal={({isModalOpen, onToggleModal}) => (
                      <LeaveUpdateApprovalStatusModal
                        title={t('leaves.LeaveRequestDetail.rejectModalTitle')}
                        description={t(
                          'leaves.LeaveRequestDetail.rejectModalDescription'
                        )}
                        status={LEAVE_STATUS_REJECTED}
                        onClose={onToggleModal}
                        leaveRequestId={id || ''}
                        approvalId={userDetails?.data?.id || ''}
                        open={isModalOpen}
                      />
                    )}
                  />
                  <ModalController
                    trigger={({onToggleModal}) => (
                      <Button variant="contained" onClick={onToggleModal}>
                        {t('leaves.LeaveRequestDetail.approveButton')}
                      </Button>
                    )}
                    modal={({isModalOpen, onToggleModal}) => (
                      <LeaveUpdateApprovalStatusModal
                        title={t('leaves.LeaveRequestDetail.approveModalTitle')}
                        description={t(
                          'leaves.LeaveRequestDetail.approveModalDescription'
                        )}
                        status={LEAVE_STATUS_APPROVED}
                        onClose={onToggleModal}
                        leaveRequestId={id || ''}
                        approvalId={userDetails?.data?.id || ''}
                        open={isModalOpen}
                      />
                    )}
                  />
                </RoleGuard>
              )}
              {data.userId === userDetails?.data?.id && (
                <>
                  {data?.status === LEAVE_STATUS_IN_PROCESS && (
                    <ModalController
                      trigger={({onToggleModal}) => (
                        <Button variant="outlined" onClick={onToggleModal}>
                          {t('leaves.LeaveRequestDetail.cancelButton')}
                        </Button>
                      )}
                      modal={({isModalOpen, onToggleModal}) => (
                        <LeaveRequestCancellationModal
                          leaveRequestId={id}
                          leaveData={data}
                          onClose={onToggleModal}
                          open={isModalOpen}
                        />
                      )}
                    />
                  )}
                  {data?.status === LEAVE_STATUS_DRAFT && (
                    <>
                      <ModalController
                        trigger={({onToggleModal}) => (
                          <Button variant="outlined" onClick={onToggleModal}>
                            {t('leaves.LeaveRequestDetail.deleteButton')}
                          </Button>
                        )}
                        modal={({isModalOpen, onToggleModal}) => (
                          <LeaveRequestDeleteModal
                            leaveData={data}
                            onClose={onToggleModal}
                            open={isModalOpen}
                          />
                        )}
                      />
                      <ModalController
                        trigger={({onToggleModal}) => (
                          <Button variant="outlined" onClick={onToggleModal}>
                            {t('leaves.LeaveRequestDetail.editButton')}
                          </Button>
                        )}
                        modal={({isModalOpen, onToggleModal}) => (
                          <LeaveRequestUpdateModal
                            leaveData={data}
                            open={isModalOpen}
                            onClose={onToggleModal}
                          />
                        )}
                      />
                      <ModalController
                        trigger={({onToggleModal}) => (
                          <Button variant="contained" onClick={onToggleModal}>
                            {t(
                              'leaves.LeaveRequestDetail.sendToApprovalsButton'
                            )}
                          </Button>
                        )}
                        modal={({isModalOpen, onToggleModal}) => (
                          <LeaveRequestInProcessModal
                            leaveData={data}
                            onClose={onToggleModal}
                            open={isModalOpen}
                          />
                        )}
                      />
                    </>
                  )}
                </>
              )}
              {data?.status === LEAVE_STATUS_APPROVED && (
                <RoleGuard renderForAdmin>
                  <ModalController
                    trigger={({onToggleModal}) => (
                      <Button variant="outlined" onClick={onToggleModal}>
                        {t(
                          'leaves.LeaveRequestDetail.approvedCancellationButton'
                        )}
                      </Button>
                    )}
                    modal={({isModalOpen, onToggleModal}) => (
                      <LeaveRequestApprovedCancellationModal
                        leaveData={data}
                        leaveRequestId={id}
                        onClose={onToggleModal}
                        open={isModalOpen}
                      />
                    )}
                  />
                </RoleGuard>
              )}
            </div>
          </div>
          {data?.status === LEAVE_STATUS_REJECTED &&
            data.userId === userDetails?.data?.id && (
              <Alert className="mb-6" severity="warning">
                {t('leaves.LeaveRequestDetail.rejectedMessage')}
              </Alert>
            )}
          <Grid container spacing={4}>
            <Grid item xs={8}>
              <BlankCard title={t('leaves.LeaveRequestDetail.request.resume')}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography variant="body2">
                      {t('leaves.LeaveRequestDetail.request.userName')}
                    </Typography>
                    <Typography variant="body1">{data.userName}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2">
                      {t('leaves.LeaveRequestDetail.request.createdAt')}
                    </Typography>
                    <Typography variant="body1">
                      {dayjs(data.createdAt).format('DD/MM/YYYY')}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2">
                      {t('leaves.LeaveRequestDetail.request.type')}
                    </Typography>
                    <Typography variant="body1">
                      {data.typeOfLeave?.description}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2">
                      {t('leaves.LeaveRequestDetail.request.daysOff')}
                    </Typography>
                    <Typography variant="body1">
                      {data.daysOff} {t('common.days')}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2">
                      {t('leaves.LeaveRequestDetail.request.timeFrame')}
                    </Typography>
                    <Typography variant="body1">
                      {`${dayjs(data.startDate).format('DD/MM/YYYY')} - ${dayjs(
                        data.endDate
                      ).format('DD/MM/YYYY')}`}
                    </Typography>
                  </Grid>
                  {!!data?.scheduleOptions?.length && (
                    <Grid item xs={6}>
                      <Typography variant="body2">
                        {extraInfoPeriod(data)}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
                <Typography variant="h6">Últimas evidencias</Typography>
                <List
                  leftIcon={<ImageIcon />}
                  renderItem={(children, item) => (
                    <ModalController
                      key={item.primaryText}
                      trigger={({onToggleModal}) => (
                        <ListItemButton onClick={onToggleModal}>
                          {children}
                        </ListItemButton>
                      )}
                      modal={({isModalOpen, onToggleModal}) => (
                        <GqlImagePreviewModal
                          title={item.primaryText}
                          isModalOpen={isModalOpen}
                          onToggleModal={() => {
                            onToggleModal();
                          }}
                          imageReference={item.value.src}
                        />
                      )}
                    />
                  )}
                  items={processEvidences(data.evidences).map(
                    (evidence: Evidence) => ({
                      primaryText: evidence.evidenceType,
                      secondaryText: '',
                      value: {
                        src: evidence.url,
                      },
                    })
                  )}
                />
              </BlankCard>
            </Grid>
            <Grid item xs={4}>
              <BlankCard
                title={t('leaves.LeaveRequestDetail.approvalsChainTitle')}
              >
                <StatusList
                  items={(data.approvals || []).map((approval: Approval) => ({
                    primaryText: approval.approvalRole,
                    secondaryText: (
                      <div>
                        <Typography variant="body2">
                          {t(leaveStatusLabels(approval.approvalStatus))}
                        </Typography>
                        <Typography variant="caption">
                          {approval.comment}
                        </Typography>
                      </div>
                    ),
                    icon: getEvidencIcon(approval.approvalStatus),
                  }))}
                />
              </BlankCard>
            </Grid>
          </Grid>
        </div>
      )}
    </GqlQueryController>
  );
};

export default LeaveRequestDetail;
