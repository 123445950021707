import {gql} from '@apollo/client';

export const CREATE_LEAVE_REQUEST = gql`
  mutation CreateLeaveRequest($leaveRequest: LeaveRequestCreateInput!) {
    createLeaveRequest(leaveRequest: $leaveRequest) {
      id
      assignationType
      typeOfLeave {
        id
      }
      startDate
      endDate
      approvals {
        approvalId
        approvalRole
        approvalStatus
        order
      }
      createdAt
      daysOff
      evidences {
        evidenceType
        url
      }
      status
      userId
      userName
    }
  }
`;

export const ATTACH_EVIDENCE = gql`
  mutation AttachEvidence(
    $leaveRequestId: String!
    $evidenceType: String!
    $imageValue: String!
    $filename: String!
  ) {
    attachEvidence(
      leave_request_id: $leaveRequestId
      evidence_type: $evidenceType
      image_value: $imageValue
      filename: $filename
    ) {
      evidenceType
      url
    }
  }
`;

// TODO: update this mutation
export const UPDATE_LEAVE_REQUEST_DRAFT = gql`
  mutation UpdateLeaveRequestDraft(
    $leaveRequest: LeaveRequestUpdateDraftInput!
  ) {
    updateLeaveRequestDraft(leaveRequest: $leaveRequest) {
      id
    }
  }
`;

export const UPDATE_LEAVE_REQUEST_STATUS = gql`
  mutation UpdateLeaveRequestStatus(
    $leaveRequest: LeaveRequestUpdateStatusInput!
  ) {
    updateLeaveRequestStatus(leaveRequest: $leaveRequest) {
      id
    }
  }
`;

export const UPDATE_APPROVAL_STATUS = gql`
  mutation UpdateApprovalStatus(
    $approvalId: String!
    $leaveRequestId: String!
    $status: ApprovalStatus!
  ) {
    updateApprovalStatus(
      approvalId: $approvalId
      leaveRequestId: $leaveRequestId
      status: $status
    ) {
      id
    }
  }
`;

export const CREATE_ADDITIONAL_LEAVE = gql`
  mutation CreateAdditionalLeave(
    $userId: String!
    $type: String!
    $initialDate: String!
    $expirationDate: String!
    $days: Float!
    $description: String!
  ) {
    createAdditionalLeave(
      userId: $userId
      type: $type
      initialDate: $initialDate
      expirationDate: $expirationDate
      days: $days
      description: $description
    ) {
      id
    }
  }
`;

export const DELETE_ADDITIONAL_LEAVE = gql`
  mutation DeleteAdditionalLeave(
    $userId: String!
    $additionalLeaveId: String!
  ) {
    deleteAdditionalLeave(
      userId: $userId
      additionalLeaveId: $additionalLeaveId
    )
  }
`;

export const CANCEL_APPROVED_LEAVE_REQUEST = gql`
  mutation CancelApproval(
    $cancelApprovalRequest: LeaveRequestCancelApprovalInput!
  ) {
    cancelApproval(cancelApprovalRequest: $cancelApprovalRequest) {
      id
    }
  }
`;
